* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: #e7e7e7;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a {
  text-decoration: none;
}

.height-scroll {
  margin-top: 0px;
}

.height-scroll-lg {
  margin-top: 0px;
}

@media only screen and (max-height: 100px) {
  .height-scroll {
    margin-top: 450px;
  }
  .height-scroll-lg {
    margin-top: 550px;
  }
}

@media only screen and (max-height: 200px) {
  .height-scroll {
    margin-top: 350px;
  }
  .height-scroll-lg {
    margin-top: 450px;
  }
}

@media only screen and (max-height: 600px) {
  .height-scroll {
    margin-top: 250px;
  }
  .height-scroll-lg {
    margin-top: 350px;
  }
}
